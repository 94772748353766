<template>
 <div
  class="bg-white overflow-hidden shadow rounded-lg panel_width mt-1 relative">
  <!-- <div class="absolute">
   <SwitchGroup as="div" class="flex items-center p-2 gap-2">
    <SwitchLabel as="span" class="">
     <span
      :class="[!showWaitingDuration ? 'text-gray-700' : 'text-gray-500', 'text-xs font-medium ']"
      >talking</span
     >
    </SwitchLabel>
    <Switch
     v-model="enabled"
     :class="[
      showWaitingDuration ? 'bg-indigo-600' : 'bg-indigo-600',
      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
     ]"
    >
     <span
      aria-hidden="true"
      :class="[
       showWaitingDuration ? 'translate-x-5' : 'translate-x-0',
       'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
      ]"
     />
    </Switch>
    <SwitchLabel as="span" class="">
     <span
      :class="[showWaitingDuration ? 'text-gray-700' : 'text-gray-500', 'text-xs font-medium ']"
      >waiting</span
     >
    </SwitchLabel>
   </SwitchGroup>
  </div> -->
  <!-- <div class="flex items-center justify-center mt-1">
   <div class="" v-if="name">
    <h3 class="text-gray-600 text-md capitalize">{{ pbxElementType }} - {{ name }}</h3>
   </div>
  </div> -->
  <div
   v-if="
    selectedAnsweredType === 'day' ||
    selectedAnsweredType === 'month' ||
    selectedAnsweredType === 'week' ||
    selectedAnsweredType === 'weekNumber'
   "
   class="px-3 py-4 sm:p-4">
   <BasicColumnChartDaily
    :seriesForColumn="this.seriesForColumn"
    :categoriesForColumn="this.categoriesForColumn"
    :seriesForColumnLine="seriesForColumnLine"
    :categoriesForLine="categoriesForLine"
    width="100%"
    height="500"
    :title="title"
    :totalAnswered="this.getAnsweredNumb(this.seriesForColumn)"
    :totalUnanswered="this.getUnansweredNumb(this.seriesForColumn)"
    @loading="$emit('loading', false)" />
  </div>
  <!-- <div
   v-if="selectedAnsweredType === 'week'"
   @click.prevent="openDetail"
   class="px-3 py-4 sm:p-4 ">
   <BasicColumnChartWeekDay
    :seriesForColumn="this.seriesForColumn"
    :categoriesForColumn="this.categoriesForColumn"
    width="100%"
    height="500"
    :title="title"
    :totalAnswered="this.getAnsweredNumb(this.seriesForColumn)"
    :totalUnanswered="this.getUnansweredNumb(this.seriesForColumn)"
    @loading="$emit('loading', false)" />
  </div> -->
  <div
   v-if="
    this.$route.name !== 'ExpertStatisticsCallDurations' &&
    this.$route.name !== 'ExpertStatisticsCallDurationsWaitingTime' &&
    this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekDay' &&
    this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekYear' &&
    selectedAnsweredType === 'hour'
   "
   class="px-3 py-4 sm:p-4">
   <BasicColumnChart
    :seriesForColumn="this.seriesForColumn"
    :categoriesForColumn="this.categoriesForColumn"
    :seriesForColumnLine="seriesForColumnLine"
    :categoriesForLine="categoriesForLine"
    :isHourlyChart="isHourlyChart"
    width="100%"
    height="500"
    :title="title"
    :totalAnswered="this.getAnsweredNumb(this.seriesForColumn)"
    :totalUnanswered="this.getUnansweredNumb(this.seriesForColumn)"
    @loading="$emit('loading', false)"
    @setHourlyChart="$emit('setHourlyChart', $event)" />
  </div>

  <div
   v-else-if="
    this.$route.name === 'ExpertStatisticsCallDurations' &&
    selectedDurationMenuItem === 'durationWaitingTime'
   "
   class="px-3 py-4 sm:p-4">
   <BasicColumnChartCallDurationsPbx
    :seriesForColumn="this.seriesForColumn"
    :categoriesForColumn="this.categoriesForColumn"
    width="100%"
    height="500"
    :title="title"
    :totalUnanswered="this.getUnansweredNumb(this.seriesForColumn)"
    @loading="$emit('loading', false)"
    :totalDuration="totalDuration" />
  </div>
  <div
   v-if="
    this.$route.name === 'ExpertStatisticsCallDurations' &&
    selectedDurationMenuItem === 'percentageWaitingTime'
   "
   class="px-3 py-4 sm:p-4 overflow-x-auto">
   <basic-column-chart-call-durations-waiting-time
    :seriesForColumn="this.seriesForColumn"
    :categoriesForColumn="this.categoriesForColumn"
    width="100%"
    height="500"
    :title="title"
    :totalUnanswered="this.getUnansweredNumb(this.seriesForColumn)"
    @loading="$emit('loading', false)"
    :totalDuration="totalDuration"
    @waitingTimeRange="$emit('waitingTimeRange', $event)"
    :waitingTime="waitingTime" />
  </div>
 </div>
</template>

<script>
import BasicColumnChartDaily from "../BasicColumnChartDaily.vue";
import BasicColumnChartWeekDay from "../BasicColumnChartWeekDay.vue";
import BasicColumnChartCallDurationsWaitingTime from "../BasicColumnChartCallDurationsWaitingTime.vue";
import BasicColumnChartCallDurationsPbx from "../BasicColumnChartCallDurationsPbx.vue";
import BasicColumnChart from "../BasicColumnChart.vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";

// const BasicColumnChart = defineAsyncComponent(() =>
//   import("../BasicColumnChart.vue")
// );

export default {
 props: [
  "data",
  "answered_calls_stats_day",
  "title",
  "subtitle",
  "concatenateData",
  "seriesForColumn",
  "seriesForColumnHourly",
  "categoriesForColumn",
  "categoriesForColumnHourly",
  "showChartHourly",
  "showWaitingDuration",
  "isConcat",
  "fullData",
  "name",
  "totalDuration",
  "waitingTime",
  "selectedDurationMenuItem",
  "selectedAnsweredType",
  "seriesForColumnLine",
  "categoriesForLine",
  "isHourlyChart",
 ],
 components: {
  BasicColumnChart,
  Switch,
  SwitchGroup,
  SwitchLabel,
  BasicColumnChartCallDurationsPbx,
  BasicColumnChartCallDurationsWaitingTime,
  BasicColumnChartWeekDay,
  BasicColumnChartDaily,
 },
 data() {
  return {
   enabled: true,
  };
 },
 methods: {
  openDetail() {
   let queue = [];
   if (this.fullData) {
    let splitElement = this.fullData.name.split(" - ");
    queue.push(splitElement[0]);
    this.$emit("openDetail", queue);
   }
  },

  getAnsweredNumb(arr) {
   let filteredAnswered = [];
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.name === "Répondus") {
     filteredAnswered = element.data;
    }
   }
   if (filteredAnswered.length > 0) {
    return filteredAnswered.reduce((accumulator, curr) => accumulator + curr);
   } else {
    return 0;
   }
  },
  getUnansweredNumb(arr) {
   let filteredUnanswered = [];
   for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    if (element.name === "Non répondus") {
     filteredUnanswered = element.data;
    }
   }
   if (filteredUnanswered.length > 0) {
    return filteredUnanswered.reduce((accumulator, curr) => accumulator + curr);
   } else {
    return 0;
   }
  },
 },
 watch: {
  enabled: function () {
   this.$emit("toggleWaitingDuration");
  },
 },
 mounted() {
  if (!this.pbxElements || this.pbxElements.length == 0) {
   this.$emit("loading", false);
  }
 },
 created() {
  this.$emit("loading", true);
 },
 computed: {
  ...mapGetters(["pbxElements", "pbxElementType"]),
 },
};
</script>

<style></style>
