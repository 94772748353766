<template>
  <div
    class="flex justify-center pt-10 rounded-md border-gray-200 pb-10 bg-white shadow-sm"
  >
    <div class="text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        class="mx-auto h-12 w-12 text-teal-600"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
        />
      </svg>

      <h3 class="mt-2 text-lg font-medium text-gray-900">
        {{ $t("noDataFoundTitle") }}
      </h3>
      <p class="mt-1 text-base text-gray-500">
        {{ dataDescription }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dataDescription"],
};
</script>

<style></style>
