<template>
 <div id="chart">
  <apexchart
   type="bar"
   height="350"
   :options="chartOptions"
   :series="series"></apexchart>
 </div>
</template>

<script>
export default {
 props: ["series", "categories"],
 data() {
  return {
   chartOptions: {
    colors: ["#16a34a", "#E34B5F"],
    chart: {
     type: "bar",
     height: 350,
     stacked: true,
     toolbar: {
      show: true,
     },
     zoom: {
      enabled: true,
     },
    },
    responsive: [
     {
      breakpoint: 480,
      options: {
       legend: {
        position: "bottom",
        offsetX: -10,
        offsetY: 0,
       },
      },
     },
    ],
    plotOptions: {
     bar: {
      horizontal: false,
      borderRadius: 0,
      dataLabels: {
       total: {
        enabled: false,
        style: {
         fontSize: "13px",
         fontWeight: 900,
        },
       },
      },
     },
    },
    xaxis: {
     categories: this.categories,
     labels: {
      rotate: -75,
      rotateAlways: true,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      style: {
       colors: [],
       fontSize: "12px",
       fontFamily: "Helvetica, Arial, sans-serif",
       fontWeight: 500,
       cssClass: "apexcharts-xaxis-label",
      },
      /**
       * Allows users to apply a custom formatter function to x-axis labels.
       *
       * @param { String } value - The default value generated
       * @param { Number } timestamp - In a datetime series, this is the raw timestamp
       * @param { object } contains dateFormatter for datetime x-axis
       */
      formatter: function (value, timestamp, opts) {
       if (value && value.length >= 6) {
        let finalValue = "";
        let year = "";
        let month = "";
        let day = "";
        if (value.length > 0) {
         year = value.slice(0, 4);
         month = value.slice(4, 6);
         day = value.slice(6, 8);
        }
        finalValue = day + "/" + month + "/" + year;
        return finalValue;
       } else {
        return value;
       }
      },
     },
    },
    legend: {
     position: "bottom",
     offsetY: 0,
    },
    fill: {
     opacity: 1,
    },
   },
  };
 },
};
</script>

<style></style>
