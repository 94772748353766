<template>
 <div
  class="max-w-md mx-12 py-1 font-bold flex justify-center gap-3 items-center text-center rounded-md border-teal-200 bg-teal-200 shadow-sm text-gray-900">
  <font-awesome-icon icon="fa-signs-post" class="icon alt text-center items-center" />
  <!-- <SearchCircleIcon class="h-6 w-6" /> -->
  <p class="text-base">
   {{ dataDescription }}
  </p>
 </div>
</template>

<script>
import { SearchCircleIcon } from "@heroicons/vue/outline";

export default {
 props: ["dataDescription"],
 components: {
  SearchCircleIcon,
 },
};
</script>

<style></style>
