<template>
 <body class="flex items-center justify-center">
  <table class="w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed">
   <thead class="bg-gray-50 divide-y divide-gray-200">
    <tr
     class="flex flex-col flex-no-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
     v-show="this.tablePaginatedData.length > 0"
    >
     <th
      scope="col"
      class="uppercase text-xs"
      v-for="header in this.tableHeaders"
      :key="header.name"
      :title="header.description"
     >
      <div
       class="inline-flex lg:mt-0 xl:mt-0 sm:mt-0 cursor-pointer"
       @click="this.setSorting(header.sort, header.name), (header.isSort = !header.isSort)"
      >
       <span :class="[header.name === this.activeSorting ? `underline` : '', 'mr-']">
        {{ $t(header.name) }}
       </span>
       <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    :d="[
                      this.activeSorting === header.name && header.isSort
                        ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                        : this.activeSorting === header.name && !header.isSort
                        ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                        : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                    ]"
                  />
                </svg> -->
      </div>
     </th>
    </tr>
   </thead>
   <tbody
    v-show="this.tablePaginatedData.length > 0"
    class="flex-1 sm:flex-none divide-y divide-gray-200 text-center text-sm"
   >
    <tr
     v-for="(data, idx) in this.tablePaginatedData"
     :key="data.id"
     :class="[
      data.element.includes('Global') && this.$route.name === 'ExpertStatisticsQueueDetail'
       ? 'border-b-2 border-gray-400 font-medium'
       : idx % 2 === 0
       ? 'bg-white'
       : `bg-gray-50`,
      'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
     ]"
    >
     <td class="p-3">
      <!-- <span>{{ data.element }}</span> -->
      <span class="text-sm" v-if="!data.element.includes('Global')"
       >{{ data.name }} <span v-if="data.queueName">({{ data.queueName }})</span></span
      >
      <span v-else>
       <span
        class="inline-block text-sm"
        v-for="(name, idx) in splitGlobalNames(data.name)"
        :key="name"
        >{{ name }}<span v-show="idx < splitGlobalNames(data.name).length - 1">,</span></span
       >
      </span>
     </td>
     <td class="p-3">
      {{ data.calls ? data.calls : '-' }}
     </td>
     <td class="p-3">
      {{ data.sollicited ? data.sollicited : 0 }}
     </td>
     <td class="p-3">
      {{ data.answered ? data.answered : 0 }}
      <span v-if="getPercentageValue(data.calls, data.answered) > 0">
       ({{ getPercentageValue(data.calls, data.answered) }}%)
      </span>
     </td>

     <!-- <td class="p-3">
      {{ data.answered_elsewhere ? data.answered_elsewhere : 0 }}
      <span v-if="getPercentageValue(data.calls, data.answered_elsewhere) > 0">
       ({{ getPercentageValue(data.calls, data.answered_elsewhere) }}%)
      </span>
     </td> -->
     <td class="p-3">
      {{ data.redirected_by_agent ? data.redirected_by_agent : 0 }}
      <span v-if="getPercentageValue(data.calls, data.redirected_by_agent) > 0">
       ({{ getPercentageValue(data.calls, data.redirected_by_agent) }}%)
      </span>
     </td>
     <td class="p-3">
      {{ data.abandoned ? data.abandoned : 0 }}
      <span v-if="getPercentageValue(data.calls, data.abandoned) > 0">
       ({{ getPercentageValue(data.calls, data.abandoned) }}%)
      </span>
     </td>
     <!-- <td class="p-3">
      {{ data.dissuaded ? data.dissuaded : 0 }}
      <span v-if="getPercentageValue(data.calls, data.dissuaded) > 0">
       ({{ getPercentageValue(data.calls, data.dissuaded) }}%)
      </span>
     </td> -->
     <td class="py-3 px-0">
      {{ data.calls ? this.getAverageTime(data.waiting_time_on_answer, data.calls) : this.getAverageTime(data.waiting_time_on_answer, data.sollicited) }}
     </td>
     <!-- <td class="py-3 px-0">
      {{ this.getAverageTime(data.waiting_time_on_answer_in_queue, data.calls) }}
     </td> -->
     <!-- <td class="py-3 px-0">
            {{
              this.getAverageTime(data.waiting_time_on_abandoned, data.calls)
            }}
          </td> -->
     <td class="py-3 px-0">
      {{ this.getAverageTime(data.duration, data.answered) }}
     </td>
     <!-- <td class="p-3">
      {{ data.answered_00_30 ? data.answered_00_30 : 0 }}
      <span v-if="getPercentageValue(data.calls, data.answered_00_30) > 0">
       ({{ getPercentageValue(data.calls, data.answered_00_30) }}%)
      </span>
     </td>
     <td class="p-3">
      {{ data.answered_31_60 ? data.answered_31_60 : 0 }}
      <span v-if="getPercentageValue(data.calls, data.answered_31_60) > 0">
       ({{ getPercentageValue(data.calls, data.answered_31_60) }}%)
      </span>
     </td>
     <td class="p-3">
      {{ data.answered_61_90 ? data.answered_61_90 : 0 }}
      <span v-if="getPercentageValue(data.calls, data.answered_61_90) > 0">
       ({{ getPercentageValue(data.calls, data.answered_61_90) }}%)
      </span>
     </td>
     <td class="p-3">
      {{ data["answered_90+"] ? data["answered_90+"] : 0 }}
      <span v-if="getPercentageValue(data.calls, data['answered_90+']) > 0">
       ({{ getPercentageValue(data.calls, data["answered_90+"]) }}%)
      </span>
     </td> -->
     <!-- <td class="p-3">
            {{ data.answered_in_qs }}
          </td> -->
    </tr>
   </tbody>
  </table>
 </body>
</template>

<script>
import { mapGetters } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
 props: ["account", "tablePaginatedData"],
 components: {
  Loading,
 },
 data() {
  return {
   tableHeaders: [
    { name: "", sort: "", isSort: false },
    {
     name: "numberOfCalls",
     sort: "",
     isSort: false,
     description: this.$t("numberOfCalls"),
    },
    {
     name: "solicited",
     sort: "",
     isSort: false,
     description: this.$t("solicited"),
    },
    {
     name: "numberOfAnsweredCalls",
     sort: "",
     isSort: false,
     description: this.$t("numberOfAnsweredCalls"),
    },
    // {
    //  name: "callsAnsweredByAnother",
    //  sort: "",
    //  isSort: false,
    //  description: this.$t("callsAnsweredByAnother"),
    // },
    {
     name: "callsTransferredByAgent",
     sort: "",
     isSort: false,
     description: this.$t("callsTransferredByAgent"),
    },
    {
     name: "abandonedCalls",
     sort: "",
     isSort: false,
     description: this.$t("abandonedCalls"),
    },
    // {
    //  name: "callsDeterred",
    //  sort: "",
    //  isSort: false,
    //  description: this.$t("callsDeterred"),
    // },
    {
     name: "averageWaitingTime",
     sort: "",
     isSort: false,
     description: this.$t("averageWaitingTime"),
    },
    // {
    //   name: "averageWaitingTimeQueue",
    //   sort: "",
    //   isSort: false,
    //   description: this.$t("averageWaitingTimeQueue"),
    // },
    // {
    //   name: "dropoutTime",
    //   sort: "",
    //   isSort: false,
    //   description: this.$t("dropoutTime"),
    // },
    {
     name: "averageCallDuration",
     sort: "",
     isSort: false,
     description: this.$t("averageCallDuration"),
    },
    // {
    //  name: "0-30",
    //  sort: "",
    //  isSort: false,
    //  description: this.$t("answered_00_30"),
    // },
    // {
    //  name: "30-60",
    //  sort: "",
    //  isSort: false,
    //  description: this.$t("answered_31_60"),
    // },
    // {
    //  name: "60-90",
    //  sort: "",
    //  isSort: false,
    //  description: this.$t("answered_61_90"),
    // },
    // {
    //  name: "90+",
    //  sort: "",
    //  isSort: false,
    //  description: this.$t("answered_90+"),
    // },
    // { name: "QS", sort: "", isSort: false, description: this.$t("qs") },
   ],
   isLoading: true,
   fullPage: false,
   activeSorting: "",
  };
 },
 methods: {
  splitGlobalNames(str) {
   let splitName = str.split(",");
   splitName.shift();
   return splitName;
  },
  getPercentageValue(total, value) {
   return ((value / total) * 100).toFixed(2);
  },
  getAverageTime(time, calls) {
   let avg = undefined;
   if (calls > 0) {
    avg = time / calls;
    if (avg > 0) {
     avg = this.timeFormater(avg);
    }
   } else {
    avg = 0;
   }
   return avg;
  },
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;
  },
  getYear(date) {
   const dateCreated = new Date(date);
   const formatedDate = dateCreated.getFullYear();

   return String(formatedDate);
  },
  getMonth(date) {
   const dateCreated = new Date(date);
   const formatedDate = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
   return String(formatedDate);
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
 },
 mounted() {
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
html,
body {
 height: 100%;
}

@media (min-width: 640px) {
 table {
  display: inline-table !important;
 }

 thead tr:not(:first-child) {
  height: 40px;
  display: none;
 }
}
@media only screen and (max-width: 640px) {
 td {
  height: 40px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
 }

 th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  height: 40px;
 }
}
</style>
