<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess"
 />
 <div class="h-full relative">
  <PbxHeader
   :selectHostname="selectHostname"
   :buttonSelected="buttonSelected"
   :parseDateRange="parseDateRange"
   :parseTimeRange="parseTimeRange"
   :periodSelector="periodSelector"
   :timeSelected="timeSelected"
   :urlType="urlType"
   :pbxMenuType="pbxMenuType"
   :elementToRemove="elementToRemove"
   :elements="extensions"
   @selector="$emit('update:selector', $event)"
   @button-selector="(...args) => $emit('button-selector', ...args)"
   @action="$emit('action', $event)"
   @concatenate="concatenateData = $event"
   @all-selected="$emit('all-selected', $event)"
   @select-hostname="this.selectHostname = $event"
   @select-hostContext="$emit('select-hostContext')"
   :formatDateToTime="this.formatDateToTime()"
   :concatenateData="concatenateData"
   :elementsLength="extensions.length"
   @elementToRemove="elementToRemove = $event"
   @activeBanner="(activeBanner = true), (key = !key)"
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   :selections="extensions"
  />
  <div class="relative items-stretch">
   <main class="">
    <div class="px-10 pt-5">
     <PbxDashboardSelector
      @selector="
       $emit('update:selector', $event),
        $emit('setSelector', $event),
        $store.dispatch('pbxPeriodSelected', $event)
      "
      @button-selector="(...args) => $emit('button-selector', ...args)"
      @action="$emit('action', $event)"
      @concatenate="concatenateData = $event"
      @all-selected="$emit('all-selected', $event)"
      :formatDateToTime="this.formatDateToTime()"
      :concatenateData="concatenateData"
      :elements="extensions"
      :elementsLength="extensions.length"
      :urlType="urlType"
      :buttonSelected="this.buttonSelected"
      @elementToRemove="elementToRemove = $event"
      @timeSelector="$emit('timeSelector', $event)"
      :clearTimeSelector="clearTimeSelector"
      :queryElement="queryElement"
      @loading="isLoading = $event"
      @selectedDurationMenuItem="selectedDurationMenuItem = $event"
     />
     <div
      v-if="showInfoText && $route.name === 'ExpertStatisticsCallDurations'"
      class="relative bg-blue-200 rounded-lg px-2 py-4 my-2"
     >
      <div class="absolute right-2 top-2">
       <XIcon
        @click="showInfoText = false"
        class="h-4 w-4 text-blue-500 hover:text-blue-600 cursor-pointer"
        aria-hidden="true"
       />
      </div>
      <div class="flex gap-2 items-start text-left">
       <div>
        <InformationCircleIcon class="h-6 w-6 text-blue-400" aria-hidden="true" />
       </div>
       <div class="space-y-1">
        <p class="text-blue-700 text-sm font-medium">
         {{ $t("expertStatsDurationsInfoText") }}
        </p>
        <p class="text-blue-700 text-sm font-medium">
         {{ $t("expertStatsDurationsInfoSubTextOne") }}
        </p>
        <p class="text-blue-700 text-sm font-medium">
         {{ $t("expertStatsDurationsInfoSubTextTwo") }}
        </p>
       </div>
      </div>
     </div>
    </div>
   </main>
   <TransitionRoot as="template" :show="showDetail ? showDetail : false">
    <Dialog as="div" class="relative z-20" @close="$emit('closeDetail', false)">
     <div class="fixed inset-0" />

     <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
       <div class="pointer-events-none fixed inset-y-0 right-0 top-12 flex max-w-full pl-10">
        <TransitionChild
         as="template"
         enter="transform transition ease-in-out duration-500 sm:duration-700"
         enter-from="translate-x-full"
         enter-to="translate-x-0"
         leave="transform transition ease-in-out duration-500 sm:duration-700"
         leave-from="translate-x-0"
         leave-to="translate-x-full"
        >
         <DialogPanel class="pointer-events-auto w-screen max-w-5xl">
          <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
           <div class="px-4 sm:px-6">
            <div class="flex items-start justify-between">
             <DialogTitle class="text-lg font-medium text-gray-900">
              {{ $t("agentDetails", { agentNumber: agentNumber, agentName: agentName }) }}
             </DialogTitle>
             <div class="ml-3 flex h-7 items-center">
              <button
               type="button"
               class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
               @click="$emit('closeDetail', false)"
              >
               <span class="sr-only">Close panel</span>
               <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
             </div>
            </div>
           </div>
           <div class="relative mt-4 flex-1">
            <!-- Replace with your content -->
            <div class="absolute border-gray-200 bg-white w-full pb-2">
             <div
              class="flex flex-col mb-0"
              v-if="
               elementAnsUnansData &&
               elementDurationData &&
               elementAnsUnansData.series &&
               elementDurationData.series &&
               elementAnsUnansData.series.length &&
               elementDurationData.series.length
              "
             >
              <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
               <div class="py-4 align-middle inline-block min-w-full sm:px-4 lg:px-4">
                <div class="shadow overflow-hidden border-b border-gray-200 rounded-md">
                 <stacked-column-chart
                  :series="elementAnsUnansData.series"
                  :categories="elementAnsUnansData.labels"
                 />
                </div>
                <div class="shadow overflow-hidden border-b border-gray-200 rounded-md">
                 <basic-line-chart
                  :series="elementDurationData.series"
                  :categories="elementDurationData.labels"
                 />
                </div>
               </div>
              </div>
             </div>
             <div v-else class="pt-20">
              <svg
               xmlns="http://www.w3.org/2000/svg"
               class="mx-auto h-12 w-12 text-gray-400"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
              >
               <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
               />
              </svg>
              <h3 class="mt-2 text-lg font-medium text-gray-900">
               {{ $t("queueHasNoData") }}
              </h3>
             </div>
            </div>
            <!-- /End replace -->
           </div>
          </div>
         </DialogPanel>
        </TransitionChild>
       </div>
      </div>
     </div>
    </Dialog>
   </TransitionRoot>
  </div>
 </div>
 <!-- <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 /> -->
</template>

<script>
import BasicLineChart from "./BasicLineChart.vue";
import StackedColumnChart from "./StackedColumnChart.vue";
import DonutsDashboardDuration from "./DonutsDashboardDuration.vue";
import QueueDetailTableOrigin from "./QueueDetailTableOrigin.vue";
import QueueDetailTable from "./QueueDetailTable.vue";
// @ is an alias to /src
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DonutsDashboard from "./DonutsDashboard.vue";
import PbxDashboardSelector from "./PbxDashboardSelector.vue";
import StatsHeading from "./StatsHeading.vue";
import StackedBarChart from "../StackedBarChart.vue";
import TimeSelector from "./TimeSelector.vue";
import BasicColumnDashboard from "./BasicColumnDashboard.vue";
import PbxHeader from "./PbxHeader.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import { mapGetters } from "vuex";
import {
 ArrowCircleRightIcon,
 ArrowCircleLeftIcon,
 InformationCircleIcon,
} from "@heroicons/vue/solid";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

const pbxMenuType = "statistics";

export default {
 name: "PbxDashboard",
 props: [
  "buttonSelected",
  "dataColumnChartQuarterHourly",
  "dataColumnChartHourly",
  "dataColumnChartQuarterHourlyCons",
  "dataColumnChartHourlyCons",
  "dataLabelsCurrentMonth",
  "dataSeriesCurrentMonth",
  "dataSeriesStacked",
  "dataSeriesConcatenated",
  "extensions",
  "periodSelector",
  "textMonth",
  "timeSelected",
  "urlType",
  "queryElement",
  "dataPbx",
  "dataElementsDuration",
  "dataPbxDay",
  "elementLevelData",
  "elementLevelDataRange",
  "waitingTimeRange",
  "outgoingCallsData",
  "showDetail",
  "elementAnsUnansData",
  "elementDurationData",
  "agentNumber",
  "agentName",
 ],
 components: {
  DonutsDashboard,
  PbxDashboardSelector,
  StatsHeading,
  StackedBarChart,
  TimeSelector,
  BasicColumnDashboard,
  PbxHeader,
  SuccessBanner,
  Loading,
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon,
  QueueDetailTable,
  QueueDetailTableOrigin,
  XIcon,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  DonutsDashboardDuration,
  InformationCircleIcon,
  StackedColumnChart,
  BasicLineChart,
 },

 data() {
  return {
   showInfoText: true,
   selectedDurationMenuItem: "percentageWaitingTime",
   selectedElement: "",
   queueData: [],
   isLoading: false,
   elementToRemove: "",
   pbxMenuType,
   showChartHourly: false,
   showWaitingDuration: true,
   seriesForColumn: [],
   categoriesForColumn: [],
   elements: [],
   concatenatedChartData: [],
   concatenatedChartDataStacked: [],
   concatenateData: false,
   montlyTotalSeries: [],
   montlyTotalLabels: [],
   totalChartSeries: [],
   totalChartLabels: [],
   pbx3cxData: {},
   callData: [],
   callDataHistory: [],
   callDataParticipants: [],
   callDataSegments: [],
   colors: [
    "#97BAA6",
    "#14233C",
    "#E34B5F",
    "#194E63",
    "#1E7889",
    "#D9B3A3",
    "#EFB0A1",
    "#5B9998",
    "#A5A59D",
    "#CAAB9F",
   ],
   pbxExtensions: [],
   totalExtension: [],
   totalCallQueue: [],
   totalOther: [],
   totalExtensionValue: "",
   pbxQueues: [],
   selectHostname: "",
   stats: [
    {
     name: "Total Extension",
     stat: "1000",
     previousStat: "900",
     change: "10%",
     changeType: "increase",
    },
    {
     name: "Total Callqueue",
     stat: "",
     previousStat: "",
     change: "",
     changeType: "",
    },
    {
     name: "Total Other",
     stat: "",
     previousStat: "",
     change: "",
     changeType: "",
    },
   ],
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
  };
 },
 methods: {
  formatDateToTime(date) {
   if (date) {
    let hours = ("0" + new Date(date).getHours()).slice(-2);
    let minutes = ("0" + new Date(date).getMinutes()).slice(-2);
    let seconds = ("0" + new Date(date).getSeconds()).slice(-2);
    return hours + ":" + minutes + ":" + seconds;
   } else {
    return "null";
   }
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
 },
 mounted() {
  // this.getPbx3cxData();
  if (!this.pbxElements || this.pbxElements.length == 0) {
   this.isLoading = false;
  }
 },
 computed: {
  ...mapGetters(["pbxElements", "hostName"]),
  currentMonthAndYearForPbx() {
   let month = new Date().getMonth() + 1;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },

 watch: {},
};
</script>

<style>
.aside__max-height {
 height: 100%;
 width: 50%;
 overflow-y: auto;
}
</style>
